import { Link } from "react-router-dom";

export const New = () => {
    return (
        <div>
            <div>
                <label htmlFor="txtTitle">Título</label>
                <input type="text" id="txtTitle" name="txtTitle" />
            </div>
            <div>
                  <label htmlFor="txtVideo">Vídeo</label>
                  <input type="file" id="txtVideo" name="txtVideo" />
            </div>
            <div>
                  <button>Salvar</button>
            </div>
            <div>
                <Link to="/dashboard">Voltar</Link>
            </div>
        </div>
    );
}