import logo from './img/logo-encant-me.jpg';
import './App.css';
//import { useContext } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import { Home } from './pages/Home';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login';
import { CreateUser } from './pages/CreateUser';
import { New } from './pages/New';
import { RequireAuth } from './contexts/Auth/RequireAuth';
//import { AuthContext } from './contexts/Auth/AuthContext';

function App() {
  //const auth = useContext(AuthContext);

  /*const handleLogout = async () => {
    await auth.signout();
    window.location.href = window.location.href;
  }*/

  return (
    <div className="App">
      <header>
        <img src={logo} alt="Logo" />
        <hr />
        <nav>
          <Link to="/">Home</Link>
          <Link to="/dashboard">Dashboard</Link>
          <Link to="/sobre">Sobre nós</Link>
          <Link to="/como-funciona">Como funciona</Link>
          <Link to="/login">Entrar</Link>
        </nav>
      </header>
      <hr />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-user" element={<CreateUser />} />
          <Route path="/new" element={<New />} />
      </Routes>
      <hr />
      <footer>
        <div id="copy-area">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                     <p className="footter">Desenvolvido por <a href="https://www.pazoul.com" target="_blank" rel="noreferrer">Pazoul</a> &copy; 2022</p>
                  </div>
              </div>
          </div>
      </div>
      </footer>
    </div>
  );
}

export default App;
