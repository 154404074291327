import { ChangeEvent, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import './App.css';

export const Login = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmailInput = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handlePasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleLogin = async () => {
        if (email && password) {
            const isLogged = await auth.signin(email, password);
            if (isLogged) {
                navigate('/dashboard');
            } else {
                alert("Não deu certo.");
            }
        }
    }
    
    return (
        <div>
            <div className = "container">
                <label htmlFor="txtLogin">Login   </label>
                <input  
                    type="text"
                    id="txtLogin"
                    name="txtLogin"
                    value={email}
                    onChange={handleEmailInput} />
            </div>
            <div>
                    <label htmlFor="txtPassword">Password</label>
                    <input 
                        type="password"
                        id="txtPassword"
                        name="txtPassword"
                        value={password}
                        onChange={handlePasswordInput} />
            </div>
            <div>
                <button onClick={handleLogin}>Logar</button>
            </div>
            <div>
                <br>
                <Link to="/create-user">Não tenho cadastro</Link>
                </br>
            </div>
            <div>
                <br>
                <Link to="/reset-password">Esqueci a senha</Link>
                </br>
            </div>
        </div>
    );
}